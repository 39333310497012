@charset "utf-8";

//ベースフォントサイズ
$baseFontSize: 16;
//スマホPSDの横幅
$SmartphoneSize: 770;

//ベース
$widthBase: 1200px;

//メインコンテンツ
$widthMain: 800px;

//サイドナビ
$widthSide: 360px;

//ベースフォント
$fontBase: "font-family: 'Noto Serif JP', serif;";

//サブフォント
$subFont: "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;

//rem() ※px指定
@function rem($pixels,$context:$baseFontSize)
{
  @return $pixels / $context * 1rem;
}

//vw()
@function vw($size) {
  @return (($size / $SmartphoneSize) * 100) + vw;
}


//setting ----------------------------------------------------

ul, ol {
  list-style: none;
}


.left {
  float: left;
}

.right {
  float: right;
}

.center {
  text-align: center;
}

.tleft {
  text-align: left;
}

.tright {
  text-align: right;
}

.pc {
  display: block;
  @media only screen and (max-width:$SmartphoneSize + px){
    display: none;
  }
}
.sp {
  display: none;
  @media only screen and (max-width:$SmartphoneSize + px){
    display: block;
  }
}
.mt5 {
  margin-top: 5px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-top: vw(5);
  }
}
.mb5 {
  margin-bottom: 5px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-bottom: vw(5);
  }
}
.ml5 {
  margin-left: 5px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-left: vw(5);
  }
}
.mr5 {
  margin-right: 5px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-right: vw(5);
  }
}
.mt10 {
  margin-top: 10px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-top: vw(10);
  }
}
.mb10 {
  margin-bottom: 10px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-bottom: vw(10);
  }
}
.ml10 {
  margin-left: 10px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-left: vw(10);
  }
}
.mr10 {
  margin-right: 10px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-right: vw(10);
  }
}
.mt15 {
  margin-top: 15px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-top: vw(15);
  }
}
.mb15 {
  margin-bottom: 15px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-bottom: vw(15);
  }
}
.ml15 {
  margin-left: 15px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-left: vw(15);
  }
}
.mr15 {
  margin-right: 15px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-right: vw(15);
  }
}
.mt20 {
  margin-top: 20px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-top: vw(20);
  }
}
.mb20 {
  margin-bottom: 20px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-bottom: vw(20);
  }
}
.ml20 {
  margin-left: 20px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-left: vw(20);
  }
}
.mr20 {
  margin-right: 20px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-right: vw(20);
  }
}
.mt30 {
  margin-top: 30px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-top: vw(30);
  }
}
.mb30 {
  margin-bottom: 30px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-bottom: vw(30);
  }
}
.ml30 {
  margin-left: 30px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-left: vw(30);
  }
}
.mr30 {
  margin-right: 30px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-right: vw(30);
  }
}
.mt40 {
  margin-top: 40px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-top: vw(40);
  }
}
.mb40 {
  margin-bottom: 40px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-bottom: vw(40);
  }
}
.ml40 {
  margin-left: 40px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-left: vw(40);
  }
}
.mr40 {
  margin-right: 40px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-right: vw(40);
  }
}

#content .entry-content{
	overflow:unset!important;
}


#content h2, #content h1.contentTitle, #content h1.entryPostTitle{
	background: url(../../images/common/bg_ct.jpg) top left repeat-y;
	color:#000;
	font-family: 'Noto Serif JP', serif!important;
}

a.btn_link{
  display:block;
  font-size:rem(26)!important;
  padding:20px 0;
  border-radius: 5px;
  background:#d16900!important;
  border:none!important;
  color:#fff!important;
  width:100%;
  text-align:center;
  text-decoration:none!important;
  &:hover{
    opacity: .8;
  }
  span{
    &:before{
      content:'';
      display:inline-block;
      width:13px;
      height:20px;
      background:url(../images/arr.svg) no-repeat center;
      background-size: cover;
      margin-right:10px;
    }
  }
  @media only screen and (max-width:$SmartphoneSize + px){
    font-size:vw(36)!important;
    padding:vw(35) 0;
    span{
      &:before{
        width:vw(20);
        height:vw(30);
      }
    }
  }
  &.weight{
    position:relative;
    overflow: unset!important;
    margin-top:50px;
    margin-bottom:30px;
    padding:35px 0;
    @media only screen and (max-width:$SmartphoneSize + px){
      padding:vw(35) 0;
      margin-top:vw(40);
      margin-bottom:vw(30);
    }
    img{
      position:absolute;
      left:0;
      bottom:0;
      @media only screen and (max-width:$SmartphoneSize + px){
        width:vw(120);
      }
    }
  }
}

.add_read{
  position: relative;
  margin-bottom: 30px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-bottom: vw(30);
  }
  .add_read_inner{
    position:absolute;
    top:0;
    right:10px;
    height:280px;
    @media only screen and (max-width:$SmartphoneSize + px){
      height:vw(433);
      right:vw(10);
    }
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    align-items: center;
    p{
      span{
        background:#fff;
        padding:8px!important;
        line-height: 1.9;
        @media only screen and (max-width:$SmartphoneSize + px){
          padding:vw(10)!important;
        }
      }
      font-family: 'Noto Serif JP', serif!important;
      font-weight:700;
      font-size:rem(20);
      margin-bottom:20px!important;
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size:vw(28);
        margin-bottom: vw(20)!important;
      }
      &:last-child{
        margin-bottom:0!important;
      }
    }
  }
  @media only screen and (max-width:970px){
    img{
      width:100%;
    }
  }
}

h3.line{
	padding:0!important;
	margin:0!important;
	border-bottom: none!important;
  font-family: 'Noto Serif JP', serif!important;
  font-size:rem(20)!important;
  margin-bottom:20px!important;
  color:#663300;
  text-align:center;
  background:linear-gradient(transparent 40%, #fefe00 40%)!important;
  br{
    display:none;
  }
  @media only screen and (max-width:$SmartphoneSize + px){
  	line-height:1.4;
    background:linear-gradient(transparent 70%, #fefe00 70%)!important;
    font-size:vw(38)!important;
    margin-bottom:vw(30)!important;
    br{
      display:block;
    }
  }
  &.large{
    font-size:rem(26)!important;
    span{
      font-size:rem(36)!important;
      color:#cc0100!important;
    }
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size:vw(28)!important;
      span{
        font-size:vw(34)!important;
      }
    }
  }
}

ul.check{
  li{
    display: flex;
    justify-content: flex-start;
    flex-wrap:wrap;
    align-items: flex-start;
  }
  p{
    &:first-child{
    	margin-bottom:0!important;
    	padding:0!important;
      position:relative;
      top:5px;
      width:25px;
      height:25px;
      @media only screen and (max-width:$SmartphoneSize + px){
        width:vw(30);
        height:vw(30);
        top:1vw;
      }
      border-radius: 50%;
      background:#d16900;
      display: flex;
      justify-content: center;
      flex-wrap:wrap;
      align-items: center;
      margin-right:10px;
      img{
        width:15px;
        height:auto;
        @media only screen and (max-width:$SmartphoneSize + px){
          width:vw(20);
        }
      }
    }
    &:last-child{
    	margin-bottom:0!important;
    	padding:0!important;
      font-family: 'Noto Serif JP', serif!important;
      font-size:rem(20)!important;
      font-weight:700;
      width:calc(100% - 35px);
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size:vw(30)!important;
        line-height:1.4!important;
        width:calc(100% - 8vw);
      }
      span{
        color:#cc0100;
      }
    }
  }
}

p.reason{
  text-align:center;
  font-family: 'Noto Serif JP', serif!important;
  font-size:rem(40)!important;
  font-weight:700;
  color:#663300;
  margin-bottom:40px!important;
  @media only screen and (max-width:$SmartphoneSize + px){
    font-size:vw(44)!important;
    line-height:1.2!important;
  }
  span{
    color:#cc0100;
    &.large{
      font-size:rem(64);
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size:vw(64);
      }
    }
  }
}

.reason_list{
  margin-bottom:vw(40);
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-bottom:vw(50);
  }
  .li_wrap{
    padding:30px 15px 15px 15px;
    border:3px solid #988819;
    box-sizing:border-box;
    position:relative;
    margin-bottom:40px;
    @media only screen and (max-width:$SmartphoneSize + px){
      padding:vw(40) vw(20) vw(20) vw(20);
    }
    &:last-child{
      margin-bottom: 0;
    }
    dt{
      font-family: 'Noto Serif JP', serif!important;
      font-size:rem(20)!important;
      line-height:1.9!important;
      color:#fff!important;
      width:100%;
      top:-20px;
      position:absolute;
      text-align:center;
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size:vw(28)!important;
        top:vw(-30);
      }
      span{
        background:#988819;
        padding:5px 20px!important;
        border:3px solid #fff;
        @media only screen and (max-width:$SmartphoneSize + px){
          padding:vw(10) vw(30);
        }
      }
    }
    dd{
      display: flex;
      justify-content: space-between;
      flex-wrap:wrap;
      align-items: center;
      margin-bottom:0!important;
      .eye{
        width:90px;
        @media only screen and (max-width:$SmartphoneSize + px){
          width:vw(100);
        }
      }
      div{
        width:calc(100% - 110px);
        @media only screen and (max-width:$SmartphoneSize + px){
          width:calc(100% - 16vw);
        }
        p{
          &:first-child{
            font-family: 'Noto Serif JP', serif!important;
            font-size:rem(30)!important;
            font-weight:700;
            color:#663300;
            @media only screen and (max-width:$SmartphoneSize + px){
              font-size:vw(32)!important;
              margin-bottom: vw(12)!important;
            }
          }
        }
      }
    }
  }
}

h3.bubble{
  font-family: 'Noto Serif JP', serif!important;
  font-size:rem(20)!important;
  font-weight:700;
  color:#fff;
  text-align:center;
  background:#663300!important;
  padding:20px 0!important;
  border-radius: 5px;
  line-height:1.4!important;
  margin-bottom:0!important;
  @media only screen and (max-width:$SmartphoneSize + px){
    font-size:vw(26)!important;
    padding:vw(20) 0!important;
  }
}
.bubble_u{
  text-align:center;
  margin-bottom:20px!important;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-bottom:vw(30)!important;
    img{
      width:vw(70);
    }
  }
}

.plan{
  .plan_wrap{
    dt{
      font-family: 'Noto Serif JP', serif!important;
      font-size:rem(24)!important;
      color:#fff!important;
      background:#4c7c1e;
      text-align:center;
      padding:16px 0!important;
      border-radius: 5px 5px 0 0;
      margin-bottom: 0!important;
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size:vw(36)!important;
        padding:vw(20) 0!important;
      }
    }
    dd{
      border:3px solid #4c7c1e;
      padding:20px!important;
      box-sizing:border-box;
      margin:0 0 40px 0!important;
      border-radius: 0 0 5px 5px;
      @media only screen and (max-width:$SmartphoneSize + px){
        padding:vw(40)!important;
        margin:0 0 vw(40) 0!important;
      }
      h4{
        font-family: 'Noto Serif JP', serif!important;
        font-size:rem(26)!important;
        text-align:center;
        color:#663300!important;
        border:none!important;
        @media only screen and (max-width:$SmartphoneSize + px){
          font-size:vw(34)!important;
        }
        span{
          background:linear-gradient(transparent 40%, #fefe00 40%)!important;
          display:inline-block;
        }
      }
      h5{
        font-size:rem(20)!important;
        color:#2c2c2c!important;
        border-bottom: 1px solid #2c2c2c;
        @media only screen and (max-width:$SmartphoneSize + px){
          font-size:vw(26)!important;
        }
      }
      .set{
        text-align:center;
        margin-bottom:30px;
        @media only screen and (max-width:$SmartphoneSize + px){
          img{
            height:vw(100);
          }
        }
      }
      .exsample{
        li{
          list-style:none!important;
          display: flex;
          justify-content: flex-start;
          flex-wrap:wrap;
          align-items: strech;
          p{
            margin-bottom:0!important;
            &.center{
              text-align:center;
            }
            &:first-child{
              color:#fff;
              width:40%;
              background:#663300;
              padding:5px!important;
              box-sizing:border-box;
              display: flex;
              justify-content: flex-start;
              flex-wrap:wrap;
              align-items: center;
              img{
                margin-right:10px!important;
              }
              @media only screen and (max-width:$SmartphoneSize + px){
                width:100%;
                padding:vw(10)!important;
              }
            }
            &:last-child{
              width:60%;
              display: flex;
              justify-content: center;
              flex-wrap:wrap;
              align-items: center;
              border:1px solid #663300;
              box-sizing:border-box;
              @media only screen and (max-width:$SmartphoneSize + px){
                width:100%;
                padding:vw(30) 0!important;
              }
            }
            @media only screen and (max-width:$SmartphoneSize + px){
              font-size:vw(26)!important;
            }
          }
        }
      }
      a.btn_link{
        width:80%;
        margin:0 auto;
      }

    }
  }
  &.reccomend{
    .plan_wrap{
      dt{
        background:#cc0100!important;
      }
      dd{
        border:3px solid #cc0100!important;
      }
    }
  }
  &.pre{
    .plan_wrap{
      dt{
        background:#988819!important;
        position:relative;
        padding:30px 0!important;
        font-size:rem(50)!important;
        img{
        	position:absolute;
        	left:-5px;
        	top:-5px;
        }
        @media only screen and (max-width:$SmartphoneSize + px){
        	padding:vw(40) 0;
        	font-size:vw(50)!important;
        	img{
        		width:vw(180);
	        	left:vw(-10);
	        	top:vw(-10);
        	}
        }
      }
      dd{
        border:3px solid #988819!important;
        h4{
        	margin: 0 0 30px 0!important;
        }
        a{
		  display:block;
		  font-size:rem(26)!important;
		  padding:50px 0;
		  background:#988819!important;
		  border:none!important;
		  color:#fff!important;
		  width:100%;
		  text-align:center;
		  position:relative;
		  &:before{
		  	content:'';
		  	display:inline-block;
		  	width:96px;
		  	height:130px;
		  	@media only screen and (max-width:$SmartphoneSize + px){
		  		width:vw(66);
		  		height:vw(100);
		  	}
		  	background:url(../images/dog_01.svg) no-repeat center;
		  	background-size:cover;
		  	position:absolute;
		  	right:0;
		  	bottom: 0;
		  }
		  &:hover{
		    opacity: .8;
		  }
		  span{
		    &:before{
		      content:'';
		      display:inline-block;
		      width:13px;
		      height:20px;
		      background:url(../images/arr.svg) no-repeat center;
		      background-size: cover;
		      margin-right:10px;
		    }
		  }
		  @media only screen and (max-width:$SmartphoneSize + px){
		    font-size:vw(36)!important;
		    padding:vw(35) 0;
		    span{
		      &:before{
		        width:vw(20);
		        height:vw(30);
		      }
		    }
		  }
        }
      }
    }
  }
}

.line_title{
  text-align:center;
  position:relative;
  font-family: 'Noto Serif JP', serif!important;
  font-size:rem(24)!important;
  font-weight:700;
  color:#663203;
  margin: 20px 0!important;
  &:before{
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    width: 100%;
    height: 5px;
    content: '';
    background: #663203;
  }
  &.tokyo{
    &:before{
      background: #fefe00;
    }
  }
  &.tokai{
    &:before{
      background: #0cf5f3;
    }
  }
  span{
    background:#fff;
    padding:10px;
    position:relative;
    z-index: 1;
  }
}

.add_voice{
  .large{
    font-weight:bold;
    color:#cc0100;
    text-align:center;
    font-size:rem(26)!important;
    margin-bottom: 20px;
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size:vw(30)!important;
      margin-bottom: vw(30);
    }
  }
  ul{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
    align-items: center;
    li{
      list-style:none!important;
      width:32%;
      &:nth-child(4){
        display:none;
      }
      @media only screen and (max-width:$SmartphoneSize + px){
        width:49%;
        &:nth-child(4){
          display:block;
        }
      }
    }
  }
}

.area_tags{
  display: flex;
  justify-content: space-between;
  flex-wrap:wrap;
  align-items: center;
  li{
    list-style:none!important;
    width:23.5%;
    text-align:center;
    background:#e9de35;
    font-size:rem(20);
    border-radius: 30px;
    padding:10px 0!important;
    @media only screen and (max-width:$SmartphoneSize + px){
      padding:vw(15) 0!important;
    }
  }
  &.tokai{
    li{
      background:#48d2d1;
    }
  }
}

.dots{
  border-top: 3px dotted #000;
  margin: 30px 0!important; 
  @media only screen and (max-width:$SmartphoneSize + px){
    margin: vw(40) 0!important; 
  }
}

.big{
  font-size:rem(30)!important;
  overflow:unset!important;
  text-align:center;
  @media only screen and (max-width:$SmartphoneSize + px){
    font-size:vw(30)!important;
  }
}

.main_offer{

  display: flex;
  justify-content: space-between;
  flex-wrap:wrap;
  align-items: strech;
  .free_tel{
  	text-decoration:none!important;
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    align-items: center;
    height:60px;
    width:calc(100% - 70px);
    box-sizing:border-box;
    border:5px solid #009a66;
    font-size:rem(20);
    font-weight:bold;
    color:#000;
    @media only screen and (max-width:$SmartphoneSize + px){
      width:calc(100% - 17vw);
      height:vw(120);
      font-size:vw(30);
    }
    .red{
      color:#cc0100!important;
    }
    .telnum{
      font-size:rem(24);
      margin-left:5px;
      &:before{
        content:'';
        display:inline-block;
        background:url(../images/free.svg) no-repeat center;
        width:45px;
        height:25px;
        background-size:cover;
      }
      @media only screen and (max-width:$SmartphoneSize + px){
        width:100%;
        text-align:center;
        font-size:vw(34);
        &:before{
          width:vw(55);
          height:vw(35);
        }
      }
    }
  }
  .linelink{
    display:block;
    width:60px;
    @media only screen and (max-width:$SmartphoneSize + px){
      width:vw(120);
    }
    img{
      width:100%;
    }
  }
}

.memorial_list{
  display: flex;
  justify-content: space-between;
  flex-wrap:wrap;
  align-items: center;
  li{
    width:49%;
    list-style:none!important;
    @media only screen and (max-width:$SmartphoneSize + px){
      width:100%;
    }
    a{
    	text-decoration:none!important;
      padding:10px;
      display: flex;
      justify-content: flex-start;
      flex-wrap:wrap;
      align-items: center;
      background:#ffffe3;
      border:1px solid #ccc;
      box-sizing:border-box;
      color:#000;
      position:relative;
      @media only screen and (max-width:$SmartphoneSize + px){
        padding:vw(20);
        font-size:vw(30);
      }
      &:after{
        content:'';
        width:13px;
        height:20px;
        background:url(../images/arr_b.svg) no-repeat center;
        background-size:cover;
        position:absolute;
        right:10px;
        top:0;
        bottom:0;
        margin:auto; 
        @media only screen and (max-width:$SmartphoneSize + px){
          width:vw(20);
          height:vw(30);
        }
      }
      .eye{
      	margin-bottom:0!important;
        width:98px;
        border:1px solid #ccc;
        box-sizing:border-box;
        @media only screen and (max-width:$SmartphoneSize + px){
          width:vw(130);
        }
      }
      .name{
      	padding:0!important;
      	margin-bottom:0!important;
        width:calc(100% - 110px);
        margin-left:10px;
        @media only screen and (max-width:$SmartphoneSize + px){
          width:calc(100% - 20vw);
          margin-left:vw(18);
        }
      }
      span{
        display:block;
        width:100%;
        font-family: 'Noto Serif JP', serif!important;
        font-size:rem(20)!important;
        color:#663203;
        font-weight:700;
        @media only screen and (max-width:$SmartphoneSize + px){
          font-size:vw(38)!important;
          margin-bottom:vw(18);
        }
      }
    }
  }
}

.add_flow{
  div{
    dt{
      cursor: pointer;
      border:2px solid #663300;
      display: flex;
      justify-content: flex-start;
      flex-wrap:wrap;
      align-items: center;
      font-size:rem(22)!important;
      padding:0!important;
      position:relative;
      p{
      	margin-bottom:0!important;
      	padding:0!important;
      }
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size:vw(24)!important;
      }
      span{
        display:inline-block;
        background:#663300;
        color:#fff;
        padding:20px 20px;
        @media only screen and (max-width:$SmartphoneSize + px){
          padding:vw(10);
          font-size:vw(18)!important;
        }
      }
      p{
        font-family: 'Noto Serif JP', serif!important;
        color:#663300;
        padding-left:10px!important;
      }
      &:after{
        content:'';
        width:13px;
        height:20px;
        background:url(../images/arr_b.svg) no-repeat center;
        background-size:cover;
        position:absolute;
        right:10px;
        top:0;
        bottom:0;
        margin:auto; 
        @media only screen and (max-width:$SmartphoneSize + px){
          width:vw(17);
          height:vw(27);
        }
      }
      &.open{
        &:after{
          transform: rotate(90deg);
        }
      }
    }
    dd{
      padding:20px 0!important;
      border-bottom:1px solid #663300;
      display:none;
    }
  }
}

.add_links{
  display: flex;
  justify-content: space-between;
  flex-wrap:wrap;
  align-items: center;
  li{
    list-style:none!important;
    width:49%;
    @media only screen and (max-width:$SmartphoneSize + px){
      width:100%;
    }
    a{
    	text-decoration:none;
      display: flex;
      justify-content: center;
      flex-wrap:wrap;
      align-items: center;
      font-size:rem(26)!important;
      padding:20px 0;
      border-radius: 5px;
      background:#d16900!important;
      border:none!important;
      color:#fff!important;
      width:100%;
      &:hover{
        opacity:.8;
      }
      p{
      	margin-bottom:0!important;
      }
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size:vw(36)!important;
        padding:vw(35) 0;
      }
      img{
        height:40px!important;
        margin-right:10px!important;
        @media only screen and (max-width:$SmartphoneSize + px){
          height:vw(50)!important;
        }
      }
    }
  }
}

#float-sp{
  display:none!important;
}

#float_sp_banner{
  display:none;
  @media only screen and (max-width:680px){
    display:block;
    position:fixed;
    bottom:0;
    z-index:1000;
    ul{
      display: flex;
      justify-content: space-between;
      flex-wrap:wrap;
      align-items: center;
      li{
        list-style:none!important;
        &:nth-child(1),&:nth-child(2){
          width:50%;
          background:#fff;
          a{
            font-size:vw(26)!important;
            color:#000;
            font-weight:bold;
            text-decoration: none;
            display: flex;
            justify-content: center;
            flex-wrap:wrap;
            align-items: center;
            span{
              &:before{
                position:relative;
                top:vw(5);
                margin-right:vw(10);
                content:'';
                background:url(../images/arr_bl.svg) no-repeat center;
                background-size:cover;
                width:vw(17);
                height:vw(27);
                display:inline-block;
              }
            }
          }
        }
        &:nth-child(1){
          a{
            background:#48d2d1;
            padding:vw(20) 0;
          }
        }
        &:nth-child(2){
          a{
            background:#e9de35;
            padding:vw(20) 0;
          }
        }
        &:nth-child(3){
          width:100%;
          background:#cc0100;
          padding:vw(20);
          a{
            text-decoration:none;
            .float_inner{
              background:#fff;
              border-radius: vw(10);
              display: flex;
              justify-content: center;
              flex-wrap:wrap;
              align-items: center;
              padding:vw(10);
              img{
                width:vw(120);
              }
              .tel_num{
                color:#000;
                font-size:vw(52);
                font-weight:bold;
                line-height:1.2;
                .text{
                  font-size:vw(24);
                  display:block;
                  span{
                    color:#cc0100;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#content #post-4868{
  &.entry-content{
    p{
      margin-top: unset;
    }
    a{
      text-decoration: none!important;
    }
  }
}


#content h3.bubble{
  border-bottom:none!important;
}

#content .entry-content p.bubble_u{
  margin-top: 0;
}

.add_price_list{
	li{
		h3{
			display: flex;
			justify-content: flex-start;
			flex-wrap:wrap;
			align-items: center;
	        font-family: 'Noto Serif JP', serif!important;
	        font-size:rem(28)!important;
	        color:#663300!important;
	        padding:0!important;
	        border-bottom: none!important;
	        span{
	        	display:block;
	        	width:100%;
	        	margin-top:15px;
	        }
	        @media only screen and (max-width:$SmartphoneSize + px){
	        	font-size:vw(36)!important;
	        	margin:vw(30) 0!important;
	        	span{
	        		font-size:vw(28)!important;
	        	}
	        }
			img{
				height:50px!important;
				margin-right:20px!important;
				@media only screen and (max-width:$SmartphoneSize + px){
					height:vw(60)!important;
					margin-right:vw(20)!important;
				}
			}
		}
		dl{
			display: flex;
			justify-content: space-between;
			flex-wrap:wrap;
			align-items: strech;
			div{
				width:33.333%;
				font-weight:bold;
				@media only screen and (max-width:$SmartphoneSize + px){
					width:100%;
				}
				dt{
					margin:0!important;
					color:#fff!important;
					background:#000;
					text-align:center;
					padding:15px 0!important;
					font-size:rem(20)!important;
					@media only screen and (max-width:$SmartphoneSize + px){
						padding:vw(20) 0!important;
						font-size:vw(30)!important;
					}
				}
				dd{
					font-size:rem(30)!important;
					text-align:center;
					padding:20px!important;
					border:3px solid #000;
					@media only screen and (max-width:$SmartphoneSize + px){
						padding:vw(30)!important;
						font-size:vw(40)!important;
					}
				}
				&:nth-child(1){
					dt{
						background:#cc0100!important;
					}
					dd{
						border:3px solid #cc0100!important;
						color:#cc0100!important;
					}
				}
				&:nth-child(2){
					dt{
						background:#4c7c1e!important;
					}
					dd{
						border:3px solid #4c7c1e!important;
						color:#4c7c1e!important;
					}
				}
				&:nth-child(3){
					dt{
						background:#663300!important;
					}
					dd{
						border:3px solid #663300!important;
						color:#663300!important;
					}
				}
			}
			&.littele{
				div{
					dd{
						font-size:rem(16)!important;
						@media only screen and (max-width:$SmartphoneSize + px){
							font-size:vw(40)!important;
							line-height:1.4!important;
						}
					}
				}
			}
		}
	}
}


#searchform input[type=submit], p.form-submit input[type=submit], form#searchform input#searchsubmit, #content form input.wpcf7-submit, #confirm-button input{
	width:60%!important;
    margin: 0 auto!important;
    padding: 20px 0!important;
    font-size:rem(30)!important;
    @media only screen and (max-width:$SmartphoneSize + px){
    	padding:vw(20) 0!important;
    	font-size:vw(36)!important;
    }
}

